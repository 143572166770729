$color_primary: #ff9800;
$color_alt: #005290;

$theme-colors: (
  'primary': #ff9800,
  'alt': #005290,
  'lightest-grey': #f3f3f3,
  'light-grey': #ebebeb,
  'mid-grey': #adadad,
  'dark-grey': #626262,
  'black': #000000,
  'white': #ffffff,
  'highlight': #ff0000
);

@function theme-color($var) {
  @return map-get($theme-colors, $var);
}

$site-colors: (
  links: theme-color('alt'),
  scrollbar: theme-color('mid-grey'),
  scrollbar-hover: theme-color('dark-grey'),
  header: theme-color('primary'),
  header-bg: theme-color('white'),
  footer: theme-color('dark-grey'),
  footer-bg: theme-color('black')
);

@function color($var) {
  @return map-get($site-colors, $var);
}
