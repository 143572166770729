@use 'sass:math';
@import '../../../styles/resources/colors';
@import '../../../styles/resources/variables';
@import '../../../styles/resources/mixins';

.off-canvas {
  width: 85%;
  padding: 3rem;

  @include page-width(sm-up) {
    width: 55rem;
  }

  @include page-width(lg-up) {
    padding: 4.5rem;
  }

  @include page-width(xl-up) {
    padding: 6rem;
  }
}
