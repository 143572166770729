@use 'sass:math';
@import '../../../../styles/resources/colors';
@import '../../../../styles/resources/variables';
@import '../../../../styles/resources/mixins';

.root {
  background-color: black;

  // Top section

  .header-top {
    background-color: theme-color('primary');
    color: white;

    .header-top-inner {
      @include page-width(sm-up) {
        > div {
          flex: 1;
        }
      }
    }
  }

  // Main navigation section

  .header-main {
    background-color: black;
    color: white;
  }

  // Nav wrap

  .navigation-wrap {
    display: flex;
    padding: 1rem 0;

    @include page-width(sm-up) {
      padding: 1rem 6rem 1rem 0;
    }

    @include page-width(lg-up) {
      padding: 0 4rem 0 0;
    }

    @include page-width(xxl-up) {
      flex: 0 1 100%;
    }
  }

  // Logo wrapper

  .logo {
    padding-top: 1rem;
    padding-bottom: 1rem;
    padding-left: 9rem;
    padding-right: 2rem;
    margin-right: -2rem;
    height: 100%;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    overflow: hidden;

    svg {
      position: relative;
      z-index: 1;
      height: 2.4rem;
      width: auto;

      @include page-width(xxl-up) {
        height: 3rem;
      }
    }

    &::before {
      content: '';
      position: absolute;
      top: 0;
      left: 5.5rem;
      width: 0;
      height: 100%;
      width: 200%;
      transform: skewX(315deg);
      background-color: white;
    }
  }

  .logo-wrap {
    @include page-width(lg-down) {
      flex: 0 1 100%;
    }
  }

  // Link List

  .links {
    margin: 0 -1rem;
    align-items: center;

    li {
      margin: 0 !important;
      padding: 0 1rem;

      a {
        @include size-p-sm;
        font-weight: bold;
      }
    }
  }

  // Account icon

  .icon-account {
    svg {
      font-size: 1.2em;

      @include page-width(md-up) {
        font-size: 1.1em;
      }
    }
  }

  // Site message

  .site-message {
    font-size: 1rem;

    @include page-width(sm-up) {
      font-size: 1.4rem;
      white-space: nowrap;
    }
  }
}

// offcanvas

.offcanvas {
  background-color: theme-color('primary') !important;

  .nav-links {
    li {
      @include page-width(sm-down) {
        margin: 0;
      }

      a {
        font-weight: bold;
      }
    }
  }
}
