@use 'sass:math';
@import '../../../../styles/resources/colors';
@import '../../../../styles/resources/variables';
@import '../../../../styles/resources/mixins';

$size: 6rem;
$size_sm: 3.5rem;

.root {
  display: flex;
}

.value {
  color: black;
  display: flex;
  align-items: center;
  justify-content: center;
  border-top: 2px solid theme-color('primary');
  border-bottom: 2px solid theme-color('primary');
  background-color: white;
  width: $size - 1rem;
  height: $size - 1rem;

  @include page-width(sm-up) {
    width: $size;
    height: $size;
  }
}

.controls {
  background-color: theme-color('primary');
  color: white;
  font-size: 1.2rem;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  height: $size - 1rem;
  width: $size - 1rem;

  @include page-width(sm-up) {
    height: $size;
    width: $size;
  }
}

.disabled {
  .value {
    border-top: 2px solid theme-color('mid-grey');
    border-bottom: 2px solid theme-color('mid-grey');
  }
  .controls {
    background-color: theme-color('mid-grey');
    cursor: auto;
  }
}

.small {
  .value,
  .controls {
    height: $size_sm;
    width: $size_sm;
  }

  .controls {
    font-size: 1rem;
  }
}
