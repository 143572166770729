@use 'sass:math';
@import '../../../../../styles/resources/colors';
@import '../../../../../styles/resources/variables';
@import '../../../../../styles/resources/mixins';

.root {
  .input {
    display: block;
    width: 100%;
    background-color: white;
    color: black;
    border: 1px solid theme-color('mid-grey');
    padding: 1.9rem 1.5rem;

    &:focus {
      outline: none;
      border-color: theme-color('dark-grey');
    }
  }

  .before-search {
    height: 20rem;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    color: theme-color('mid-grey');
    margin-top: 2rem;
    padding: 1rem;
  }
}
