@use 'sass:math';
@import '../../../../styles/resources/colors';
@import '../../../../styles/resources/variables';
@import '../../../../styles/resources/mixins';

.root {
  z-index: 40;
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: center;
  background: black;
  color: white;
  transition: opacity 0.4s;
  opacity: 0;
  padding: 5px 8px;
  width: 5rem;
  height: 5rem;
  cursor: pointer;
  left: 0.5rem;
  bottom: 0.5rem;

  &.visible {
    opacity: 1;
  }
}
