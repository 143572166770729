* {
  box-sizing: border-box;
}

html {
  font-size: 62.5%;
}

html,
body {
  padding: 0;
  margin: 0;
  text-rendering: optimizelegibility;
  -webkit-font-smoothing: antialiased;
  -webkit-tap-highlight-color: transparent;
  user-select: none;
  width: 100%;
}

body {
  font-size: 1.6rem;
  font-family: $font_primary;
  overflow-x: hidden;

  main {
    min-height: 80vh;
  }
}

/* custom scrollbar */
::-webkit-scrollbar {
  width: 16px;
}
::-webkit-scrollbar-button {
  display: none;
}

::-webkit-scrollbar-track {
  box-sizing: content-box;
  background-color: transparent;
}

::-webkit-scrollbar-thumb {
  background-color: color(scrollbar);
  border-radius: 8px;
  border: 5px solid transparent;
  background-clip: content-box;
}

::-webkit-scrollbar-thumb:hover {
  background-color: color(scrollbar-hover);
}

// Type

a,
button {
  cursor: pointer;
}

a {
  color: inherit;
  text-decoration: none;
  display: inline-block;
  cursor: pointer;
  user-select: none;

  &:hover,
  &:focus,
  &:active {
    color: inherit;
  }
}

button {
  user-select: none;
}

.btn-clear {
  background-color: transparent;
  border: 0;
}

.no-scroll {
  body {
    overflow-y: hidden;

    @media (any-hover: hover) {
      padding-right: 16px;
    }
  }
}

.remove-type-margins {
  h1,
  h2,
  h3,
  h4,
  h5,
  .h1,
  .h2,
  .h3,
  .h4,
  .h5,
  p,
  .p {
    margin-bottom: 0 !important;
  }
}

// Navigation arrow

.navigation-arrow {
  i {
    transform: rotate(-45deg);
  }
}

// images

.img-fluid {
  max-width: 100%;
  height: auto;
}

// Lists

.list-clear-all {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

// Horizontal line

hr {
  border-style: solid;
}

// Table

table.table {
  width: 100%;
  max-width: 100%;
  border-spacing: 0;
  border-collapse: collapse;

  &.basic {
    tr {
      th,
      td {
        border: 1px solid black;
        vertical-align: top;
        padding: 0.5rem 1rem;
        font-size: 1.4rem;
      }

      td {
        ol,
        ul {
          margin-top: 0;
          padding-left: 20px;
        }
      }
    }
  }

  tr {
    th,
    td {
      text-align: left;
      border-bottom: 1px solid black;
      padding: 1rem;
    }

    td {
      a {
        text-decoration: underline;
      }
    }
  }
}

.table-responsive {
  display: block;
  width: 100%;
  overflow-x: auto;
  padding: 1px;
  -webkit-overflow-scrolling: touch;
  -ms-overflow-style: -ms-autohiding-scrollbar;
}

// Flex

.flex-fill {
  flex: 1;
}

// Gorgias chat

.gorgias-chat-key-eam2gc {
  bottom: 0 !important;
  right: 0.5rem !important;
  margin-bottom: -1.5rem;
}

// pre-order

.product-message {
  display: block;
  text-transform: uppercase;
  color: theme-color('highlight');
  font-weight: bold;
  font-family: $font_heading;
  border: 2px solid theme-color('highlight');
  padding: 1rem;
  font-size: 1.5rem;
}
