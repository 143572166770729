@use 'sass:math';
@import '../../../../../styles/resources/colors';
@import '../../../../../styles/resources/variables';
@import '../../../../../styles/resources/mixins';

.burger {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  width: 3rem;
  height: 2rem;
  background: transparent;
  border: 00;
  cursor: pointer;
  padding: 0;

  @include page-width(xl-up) {
    width: 4rem;
  }

  &:focus {
    outline: none;
  }

  span {
    display: block;
    width: 3rem;
    height: 2px;
    background: white;
    position: relative;

    @include page-width(xl-up) {
      width: 4rem;
    }
  }
}
