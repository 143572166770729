@use 'sass:math';
@import '../../../../styles/resources/colors';
@import '../../../../styles/resources/variables';
@import '../../../../styles/resources/mixins';

.top-navigation,
.bottom-navigation,
.external-navigation {
  align-items: center;
  justify-content: center;

  @include page-width(lg-down) {
    flex-flow: column !important;
  }

  li {
    > a,
    > span {
      color: theme-color('mid-grey');
      font-weight: 500;
      font-family: $font_primary;
      transition: color 0.3s;
    }

    > a {
      &:hover {
        color: black;
      }
    }
  }
}

.top-navigation {
  li {
    @include page-width(lg-up) {
      &::after {
        content: '|';
        color: theme-color('primary');
        margin: 0 3rem;
        @include size-h4;
      }

      &:last-child {
        &::after {
          display: none;
        }
      }
    }

    > a,
    > span {
      @include size-h4;
    }
  }
}

.bottom-navigation {
  li {
    @include page-width(sm-down) {
      margin: 0.2rem 0 !important;
    }

    @include page-width(lg-up) {
      margin: 0 2rem 1rem !important;
    }

    > a,
    > span {
      @include page-width(sm-up) {
        @include size-p-lg;
      }
    }
  }
}

.external-navigation {
  li {
    @include page-width(lg-up) {
      margin: 0 1.5rem !important;
    }
  }
}

.list-navigation {
  list-style-type: none;
  padding: 0;
  margin: 0;
  display: flex;
  align-items: center;
  justify-content: center;

  li {
    margin: 0 1rem 1.5rem;

    @include page-width(lg-up) {
      margin: 0 1.5rem;
    }

    > span,
    > a {
      color: theme-color('mid-grey');
      @include size-p-lg;
    }

    > a {
      transition: color 0.3s;

      &:hover {
        color: black;
      }
    }
  }
}

.cookie-settings {
  background: transparent !important;
  color: theme-color('mid-grey') !important;
  transition: color 0.3s !important;
  font-size: 1.6rem;
  font-weight: 500;
  font-family: $font_primary;
  transition: color 0.3s;
  padding: 0;

  &:hover {
    color: black !important;
  }
}
