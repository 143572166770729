@use 'sass:math';
@import '../../../../styles/resources/colors';
@import '../../../../styles/resources/variables';
@import '../../../../styles/resources/mixins';

// details section

.product-details {
  max-width: 100%;
  width: math.div(map-get($container-widths, 'lg'), 2);
  padding: 3rem 0;

  @include page-width(sm-up) {
    padding: 2rem 0;
  }

  @include page-width(xl-down) {
    position: relative !important;
    top: auto !important;
    left: auto !important;
    transform: none !important;
  }
}

// images section

.image-details {
  width: 100%;
  height: 100%;
  position: relative;
}

// categories

.categories {
  list-style-type: none;
  padding: 0;
  margin: 0 -0.5rem 1rem;
  flex-wrap: wrap;
  display: none;

  @include page-width(xl-up) {
    display: flex;
  }

  @media (max-height: 850px) {
    display: none;
  }

  li {
    padding: 0 0.5rem 1rem;
    white-space: nowrap;
  }
}

.category-item {
  display: block;
  border: 1px solid theme-color('mid-grey');
  color: theme-color('mid-grey');
  padding: 0.5rem 1rem;
  text-transform: uppercase;
  font-size: 1rem;
}

// price

.price {
  color: black;
  font-family: $font_heading;
  font-weight: bold;

  del {
    margin-right: 0.5rem;
  }

  ins {
    text-decoration: none;
    color: theme-color('highlight');
  }
}

// add to basket

.submit {
  max-width: 100%;
  position: relative;

  @include page-width(md-up) {
    width: auto;
    flex: 0 1 100%;
  }

  button {
    height: 100%;
    width: 100%;
  }
}

.submit-icon {
  position: absolute;
  top: 50%;
  right: 0;
  transform: translate(-100%, -50%);
  opacity: 0;
  transition: opacity 0.2s;

  &.icon-shown {
    opacity: 1;
  }
}

// images

.image {
  position: relative;
  padding-bottom: 115%;
  width: 100%;
  background-color: theme-color('light-grey');

  img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

// Offer information

.offer-information {
  ul {
    display: flex;
    list-style-type: none;
    flex-flow: column;
    padding: 0;
    margin: 1.5rem 0 0;

    li {
      margin-bottom: 0.5rem;

      &:last-child {
        margin-bottom: 0;
      }

      .offer-item {
        display: flex;
        align-items: center;
        background-color: theme-color('light-grey');
        border-left: 5px solid theme-color('primary');
        padding: 0.5rem;
      }
    }
  }
}

// Usps

.usps {
  margin-top: 2rem;

  ul {
    display: flex;
    list-style-type: none;
    flex-flow: column;
    padding: 0;
    margin: 0;

    @include page-width(xl-up) {
      flex-flow: row;
      margin: 0 -0.5rem;
    }

    li {
      display: flex;
      align-items: center;
      margin-bottom: 0.5rem;

      @include page-width(xl-up) {
        padding: 0 0.5rem;
        margin-bottom: 0;
      }
    }
  }
}

// Notify me success message
.notify-icon {
  position: absolute;
  top: 50%;
  right: 2rem;
  transform: translate(0, -50%);
}
