@use 'sass:math';
@import '../../../../styles/resources/colors';
@import '../../../../styles/resources/variables';
@import '../../../../styles/resources/mixins';

.root {
  position: relative;

  .input {
    display: block;
    width: 100%;
    background-color: white;
    color: black;
    border: 1px solid theme-color('mid-grey');
    padding: 1.9rem 1.5rem;
    font-family: $font_primary;

    &:focus {
      outline: none;
      border-color: theme-color('dark-grey');
    }
  }

  .checkbox {
    input {
      margin: 0 0.7rem 0.5rem 0;
    }
  }

  svg {
    position: absolute;
    top: 50%;
    right: 1.5rem;
    transform: translate(0, -50%);
    pointer-events: none;
  }

  &.has-icon {
    input {
      padding: 1.9rem 3.5rem 1.9rem 1.5rem;
    }
  }
}
