@use 'sass:math';

// margin auto

.mt-auto {
  margin-top: auto;
}

.mb-auto {
  margin-bottom: auto;
}

.mx-auto {
  margin-left: auto;
  margin-right: auto;
}

.my-auto {
  margin-top: auto;
  margin-bottom: auto;
}

.mr-auto {
  margin-right: auto;
}

.ml-auto {
  margin-left: auto;
}

// media controlled margin auto

@each $label, $size in $sizes {
  @media (min-width: $size) {
    .mx-#{$label}-auto {
      margin-left: auto;
      margin-right: auto;
    }

    .my-#{$label}-auto {
      margin-top: auto;
      margin-bottom: auto;
    }

    .mr-#{$label}-auto {
      margin-right: auto;
    }

    .ml-#{$label}-auto {
      margin-left: auto;
    }
  }
}

// values

@for $i from 0 through 20 {
  .mt-#{$i} {
    margin-top: #{math.div($i, 2)}rem;
  }

  .mb-#{$i} {
    margin-bottom: #{math.div($i, 2)}rem;
  }

  .ml-#{$i} {
    margin-left: #{math.div($i, 2)}rem;
  }

  .mr-#{$i} {
    margin-right: #{math.div($i, 2)}rem;
  }

  .my-#{$i} {
    margin-top: #{math.div($i, 2)}rem;
    margin-bottom: #{math.div($i, 2)}rem;
  }

  .mx-#{$i} {
    margin-left: #{math.div($i, 2)}rem;
    margin-right: #{math.div($i, 2)}rem;
  }

  .m-#{$i} {
    margin: #{math.div($i, 2)}rem;
  }

  .pt-#{$i} {
    padding-top: #{math.div($i, 2)}rem;
  }

  .pb-#{$i} {
    padding-bottom: #{math.div($i, 2)}rem;
  }

  .pl-#{$i} {
    padding-left: #{math.div($i, 2)}rem;
  }

  .pr-#{$i} {
    padding-right: #{math.div($i, 2)}rem;
  }

  .py-#{$i} {
    padding-top: #{math.div($i, 2)}rem;
    padding-bottom: #{math.div($i, 2)}rem;
  }

  .px-#{$i} {
    padding-left: #{math.div($i, 2)}rem;
    padding-right: #{math.div($i, 2)}rem;
  }

  .p-#{$i} {
    padding: #{math.div($i, 2)}rem;
  }
}

// negative margins

@for $i from 0 through 20 {
  .mt-n#{$i} {
    margin-top: -#{math.div($i, 2)}rem;
  }

  .mb-n#{$i} {
    margin-bottom: -#{math.div($i, 2)}rem;
  }

  .ml-n#{$i} {
    margin-left: -#{math.div($i, 2)}rem;
  }

  .mr-n#{$i} {
    margin-right: -#{math.div($i, 2)}rem;
  }

  .my-n#{$i} {
    margin-top: -#{math.div($i, 2)}rem;
    margin-bottom: -#{math.div($i, 2)}rem;
  }

  .mx-n#{$i} {
    margin-left: -#{math.div($i, 2)}rem;
    margin-right: -#{math.div($i, 2)}rem;
  }

  .m-n#{$i} {
    margin: -#{math.div($i, 2)}rem;
  }
}

// media controlled values

@each $label, $size in $sizes {
  @media (min-width: $size) {
    @for $i from 0 through 20 {
      .mt-#{$label}-#{$i} {
        margin-top: #{math.div($i, 2)}rem;
      }

      .mb-#{$label}-#{$i} {
        margin-bottom: #{math.div($i, 2)}rem;
      }

      .ml-#{$label}-#{$i} {
        margin-left: #{math.div($i, 2)}rem;
      }

      .mr-#{$label}-#{$i} {
        margin-right: #{math.div($i, 2)}rem;
      }

      .my-#{$label}-#{$i} {
        margin-top: #{math.div($i, 2)}rem;
        margin-bottom: #{math.div($i, 2)}rem;
      }

      .mx-#{$label}-#{$i} {
        margin-left: #{math.div($i, 2)}rem;
        margin-right: #{math.div($i, 2)}rem;
      }

      .m-#{$label}-#{$i} {
        margin: #{math.div($i, 2)}rem;
      }

      .pt-#{$label}-#{$i} {
        padding-top: #{math.div($i, 2)}rem;
      }

      .pb-#{$label}-#{$i} {
        padding-bottom: #{math.div($i, 2)}rem;
      }

      .pl-#{$label}-#{$i} {
        padding-left: #{math.div($i, 2)}rem;
      }

      .pr-#{$label}-#{$i} {
        padding-right: #{math.div($i, 2)}rem;
      }

      .py-#{$label}-#{$i} {
        padding-top: #{math.div($i, 2)}rem;
        padding-bottom: #{math.div($i, 2)}rem;
      }

      .px-#{$label}-#{$i} {
        padding-left: #{math.div($i, 2)}rem;
        padding-right: #{math.div($i, 2)}rem;
      }

      .p-#{$label}-#{$i} {
        padding: #{math.div($i, 2)}rem;
      }
    }
  }
}
