@use 'sass:math';
@import '../../../../styles/resources/colors';
@import '../../../../styles/resources/variables';
@import '../../../../styles/resources/mixins';

.root {
  padding: 2rem;
  width: 85%;

  @include page-width(sm-up) {
    width: 50rem;
    padding: 3rem;
  }

  @include page-width(lg-up) {
    padding: 4.5rem;
  }

  @include page-width(xl-up) {
    padding: 6rem;
  }

  &.open {
    transform: translate(0, 0);
  }
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 3rem;
  padding-bottom: 3rem;
  border-bottom: 2px solid black;
}

.inner {
  flex: 1;
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  transition: opacity 0.5s;
  z-index: 19;
  opacity: 0;
  pointer-events: none;

  &.open {
    pointer-events: auto;
    opacity: 1;
  }
}
