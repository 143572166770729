@use 'sass:math';
@import '../../../../styles/resources/colors';
@import '../../../../styles/resources/variables';
@import '../../../../styles/resources/mixins';

.root {
  position: relative;
  padding: 1rem;
  margin-top: 2rem;

  .title {
    line-height: 1;
    background-color: black;
    color: white;
    padding: 1rem;
    margin-bottom: 0.7rem;
  }
}
