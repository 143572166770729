@use 'sass:math';
@import '../../../../../styles/resources/colors';
@import '../../../../../styles/resources/variables';
@import '../../../../../styles/resources/mixins';

.navigation,
.sub-navigation {
  list-style-type: none;
  padding: 0;
}

.navigation {
  margin: 0 -2rem;
  display: none;

  @include page-width(lg-up) {
    display: flex;
  }
}

.navigation-item {
  position: relative;
  padding: 0 1rem;

  @include page-width(xxl-up) {
    padding: 0 1.5rem;
  }

  &:hover {
    &::after {
      width: 100%;
    }

    > .navigation-dropdown {
      display: block;
    }
  }

  &::after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 50%;
    height: 5px;
    width: 100%;
    background-color: theme-color('primary');
    transform: skewX(315deg) translate(-50%, 0);
    width: 0;
    overflow: hidden;
    transition: width 0.3s;
  }

  > a,
  > span {
    text-transform: uppercase;
    font-weight: bold;
    color: white;
    display: block;
    padding: 3rem 0.5rem;
    white-space: nowrap;
  }
}

.navigation-dropdown {
  display: flex;
  position: absolute;
  top: 100%;
  left: 0;
  flex-flow: column;
  background-color: white;
  padding: 3rem;
  border: 2px solid theme-color('primary');
  z-index: 20;
  display: none;

  &:hover {
    display: block;
  }

  @include page-width(lg-up) {
    min-width: 30rem;
  }
}

.navigation-group,
.sub-navigation-item {
  > a,
  > span {
    text-transform: uppercase;
    color: black;
    padding: 0.2rem 0;
    @include size-p;
  }
}

.navigation-group {
  margin-bottom: 1rem;

  &:last-of-type {
    margin-bottom: 0;
  }

  > a,
  > span {
    font-weight: 700;
    margin-bottom: 0.5rem;
  }
}

.sub-navigation-item {
  white-space: nowrap;

  > a,
  > span {
    font-weight: 500;
    display: flex;
    justify-content: space-between;
    align-items: center;

    &:hover {
      .arrow {
        transform: translate(-5px, 0);
        color: theme-color('primary');
      }
    }
  }
}

.arrow {
  display: inline-block;
  margin-left: 3rem;
  transition: all 0.3s ease;
}
