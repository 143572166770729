@use 'sass:math';
@import '../../../../styles/resources/colors';
@import '../../../../styles/resources/variables';
@import '../../../../styles/resources/mixins';

.list {
  list-style-type: none;
  display: flex;
  flex-flow: column;
  padding: 0;
  margin: 0;

  li {
    margin: 0 0 0.5rem;
  }

  &.row {
    flex-flow: row;
    flex-wrap: wrap;

    li {
      margin: 0 0.5rem 1rem;
    }
  }
}

.arrow {
  transform: rotate(-45deg);
  margin-left: 1.5rem;
}
