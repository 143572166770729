.globale-hide {
  display: none;
}

.globale-show {
  display: block;
}

// only displays when country is not controlled by GE.
// we can either skip this, or attach something to the body
// or reenable their css

// [data-ge-price]{
//   visibility: hidden;
// }
