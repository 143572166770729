@use 'sass:math';
@import '../../../../styles/resources/colors';
@import '../../../../styles/resources/variables';
@import '../../../../styles/resources/mixins';

$size: 4.5rem;
$border-width: 3px;
$padding: 1px;
$color-default: #dfdfdf;
$color-not-selected: #dfdfdf;
$color-selected: #ff9e31;
$color-disabled: #e3bfbf;

.root {
  position: relative;
  overflow: hidden;
  border-radius: 50%;
  padding: $padding;
  border: $border-width solid $color-not-selected;
  cursor: pointer;
  height: $size - 0.5rem;
  width: $size - 0.5rem;

  @include page-width(sm-up) {
    height: $size;
    width: $size;
  }

  &.small {
    height: $size / 1.5;
    width: $size / 1.5;
    border-width: $border-width - 1px;
  }

  &:hover {
    .outer {
      opacity: 1;
    }
  }
}

.selected {
  border: $border-width solid $color-selected !important;

  .outer {
    opacity: 1;
  }
}

.disabled {
  border: $border-width solid $color-disabled;

  &:after {
    content: '';
    display: block;
    width: $border-width;
    height: $size;
    position: absolute;
    top: 0;
    left: 50%;
    transform-origin: 100% 50%;
    transform: translateX(-#{$border-width}) rotate(30deg);
    z-index: 2;
    background-color: $color-disabled;
    pointer-events: none;
  }
}

.not-available {
  cursor: not-allowed;
}

.outer {
  height: 100%;
  width: 100%;
  border-radius: 50%;
  overflow: hidden;
  position: relative;
  // opacity: 0.8;
  transition: opacity 0.3s;
  background-color: $color-default;
}

.inner {
  position: absolute;
  height: 100%;
  width: 100%;
  top: -$padding/2;
  left: calc(50% - $padding/2);
  transform-origin: 0 50%;
  transform: rotate(45deg);
}
