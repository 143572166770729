@use 'sass:math';
@import '../../../../../styles/resources/colors';
@import '../../../../../styles/resources/variables';
@import '../../../../../styles/resources/mixins';

.root {
  display: flex;
  align-items: center;
  margin-top: -2px;
  margin-bottom: -2px;
  margin-right: 10px;

  img {
    cursor: pointer;
    object-fit: cover;
    height: 24px;
    width: 24px;
  }
}
