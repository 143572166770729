// colours

@each $name, $color in $theme-colors {
  .bg-#{$name} {
    background-color: $color !important;
  }

  .color-#{$name} {
    color: $color !important;
  }

  .border-#{$name} {
    border-color: $color !important;
  }
}

// stretch container

.stretch-container {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

// responsive block

.responsive-block {
  position: relative;
  padding-bottom: 100%;

  &--lg {
    padding-bottom: 75%;
  }

  &--md {
    padding-bottom: 56.25%;
  }

  &--sm {
    padding-bottom: 40%;
  }
}

// embed responsive

.embed-responsive {
  position: relative;
  display: block;
  width: 100%;
  padding: 0;
  overflow: hidden;

  &::before {
    padding-top: 56.25%;
  }

  &-item,
  iframe {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 0;
  }
}

// z-index and position

.position-relative {
  position: relative;
}

@for $i from 1 through 12 {
  .z-index-#{$i} {
    z-index: $i;
  }
}

// Cursor

.cursor-pointer {
  cursor: pointer;
}
