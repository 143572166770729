// display

.d-flex {
  display: flex;
}

.d-inline-flex {
  display: inline-flex;
}

.d-block {
  display: block;
}

.d-inline-block {
  display: inline-block;
}

.d-none {
  display: none;
}

@each $label, $size in $sizes {
  @media (min-width: $size) {
    .d-#{$label}-flex {
      display: flex;
    }

    .d-#{$label}-inline-flex {
      display: inline-flex;
    }

    .d-#{$label}-block {
      display: block;
    }

    .d-#{$label}-inline-block {
      display: inline-block;
    }

    .d-#{$label}-none {
      display: none;
    }
  }
}

// flow

.flex-row {
  flex-flow: row;
}

.flex-column {
  flex-flow: column;
}

@each $label, $size in $sizes {
  @media (min-width: $size) {
    .flex-#{$label}-row {
      flex-flow: row;
    }

    .flex-#{$label}-column {
      flex-flow: column;
    }
  }
}

// wrap

.flex-wrap {
  flex-wrap: wrap;
}

// align

.align-items-start {
  align-items: flex-start;
}

.align-items-end {
  align-items: flex-end;
}

.align-items-center {
  align-items: center;
}

.align-items-stretch {
  align-items: stretch;
}

@each $label, $size in $sizes {
  @media (min-width: $size) {
    .align-items-#{$label}-start {
      align-items: flex-start;
    }

    .align-items-#{$label}-end {
      align-items: flex-end;
    }

    .align-items-#{$label}-center {
      align-items: center;
    }

    .align-items-#{$label}-stretch {
      align-items: stretch;
    }
  }
}

.align-self-start {
  align-self: flex-start;
}

.align-self-end {
  align-self: flex-end;
}

.align-self-center {
  align-self: center;
}

.align-self-stretch {
  align-self: stretch;
}

@each $label, $size in $sizes {
  @media (min-width: $size) {
    .align-self-#{$label}-start {
      align-self: flex-start;
    }

    .align-self-#{$label}-end {
      align-self: flex-end;
    }

    .align-self-#{$label}-center {
      align-self: center;
    }

    .align-self-#{$label}-stretch {
      align-self: stretch;
    }
  }
}

// justify

.justify-content-start {
  justify-content: flex-start;
}

.justify-content-end {
  justify-content: flex-end;
}

.justify-content-center {
  justify-content: center;
}

.justify-content-between {
  justify-content: space-between;
}

@each $label, $size in $sizes {
  @media (min-width: $size) {
    .justify-content-#{$label}-start {
      justify-content: flex-start;
    }

    .justify-content-#{$label}-end {
      justify-content: flex-end;
    }

    .justify-content-#{$label}-center {
      justify-content: center;
    }

    .justify-content-#{$label}-between {
      justify-content: space-between;
    }
  }
}
