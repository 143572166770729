// align

.text-left {
  text-align: left;
}

.text-right {
  text-align: right;
}

.text-center {
  text-align: center;
}

@each $label, $size in $sizes {
  @media (min-width: $size) {
    .text-#{$label}-left {
      text-align: left;
    }

    .text-#{$label}-right {
      text-align: right;
    }

    .text-#{$label}-center {
      text-align: center;
    }
  }
}

// transform

.text-uppercase {
  text-transform: uppercase;
}

.text-lowercase {
  text-transform: lowercase;
}

.text-capitalize {
  text-transform: capitalize;
}

.text-bold {
  font-weight: bold;
}

.text-normal {
  font-weight: normal;
}

.text-underline {
  text-decoration: underline;
}

.text-nowrap {
  white-space: nowrap;
}

.text-italic {
  font-style: italic;
}

// line height

.lh-normal {
  line-height: 1;
}
