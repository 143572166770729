@use 'sass:map';
@use 'sass:math';

// container

.container,
.container-fluid {
  padding-left: 20px;
  padding-right: 20px;
  margin-left: auto;
  margin-right: auto;
  width: 100%;
  max-width: 100%;
}

.container {
  @each $label, $size in $sizes {
    @media (min-width: $size) {
      max-width: map.get($container-widths, $label);
    }
  }
}

// row
.row {
  display: flex;
  flex-wrap: wrap;
  margin-left: -20px;
  margin-right: -20px;
}

.no-gutters {
  margin-left: 0 !important;
  margin-right: 0 !important;
}

.row-sm {
  margin-left: -10px;
  margin-right: -10px;

  [class*='col-'] {
    padding-left: 10px;
    padding-right: 10px;
  }
}

.row-xs {
  margin-left: -5px;
  margin-right: -5px;

  [class*='col-'] {
    padding-left: 5px;
    padding-right: 5px;
  }
}

// The below columns are separated into their own loops
// for proper overwrite behaviour.

// all columns
@each $label, $size in $sizes {
  @for $i from 1 through 12 {
    .col-#{$label}-#{$i} {
      position: relative;
      width: 100%;
      padding-right: 20px;
      padding-left: 20px;
    }
  }
}

// fixed column sizes
@each $label, $size in $sizes {
  @for $i from 1 through 12 {
    $total_cols: math.div(12, $i);
    $percentage: math.div(100%, $total_cols);

    .col-#{$i} {
      flex: 0 0 $percentage;
      max-width: $percentage;
    }
  }
}

// media width dependant sizes
@each $label, $size in $sizes {
  @media (min-width: $size) {
    @for $i from 1 through 12 {
      $total_cols: math.div(12, $i);
      $percentage: math.div(100%, $total_cols);

      .col-#{$label}-#{$i} {
        flex: 0 0 $percentage;
        max-width: $percentage;
      }
    }
  }
}

// offset column sizes
@each $label, $size in $sizes {
  @for $i from 1 through 12 {
    $total_cols: math.div(12, $i);
    $percentage: math.div(100%, $total_cols);

    .offset-#{$i} {
      margin-left: $percentage;
    }
  }
}

// media width dependant offset sizes
@each $label, $size in $sizes {
  @media (min-width: $size) {
    @for $i from 1 through 12 {
      $total_cols: math.div(12, $i);
      $percentage: math.div(100%, $total_cols);

      .offset-#{$label}-#{$i} {
        margin-left: $percentage;
      }
    }
  }
}

// order

@for $i from 1 through 12 {
  .order-#{$i} {
    order: $i;
  }
}

@each $label, $size in $sizes {
  @media (min-width: $size) {
    @for $i from 1 through 12 {
      .order-#{$label}-#{$i} {
        order: $i;
      }
    }
  }
}

// widths

.w-100 {
  width: 100%;
}

.w-75 {
  width: 75%;
}

.w-50 {
  width: 50%;
}

.w-25 {
  width: 25%;
}

@each $label, $size in $sizes {
  @media (min-width: $size) {
    .w-#{label}-100 {
      width: 100%;
    }

    .w-#{label}-75 {
      width: 75%;
    }

    .w-#{label}-50 {
      width: 50%;
    }

    .w-#{label}-25 {
      width: 25%;
    }
  }
}

// heights

.h-100 {
  height: 100%;
}

.h-75 {
  height: 75%;
}

.h-50 {
  height: 50%;
}

.h-25 {
  height: 25%;
}

@each $label, $size in $sizes {
  @media (min-width: $size) {
    .h-#{label}-100 {
      height: 100%;
    }

    .h-#{label}-75 {
      height: 75%;
    }

    .h-#{label}-50 {
      height: 50%;
    }

    .h-#{label}-25 {
      height: 25%;
    }
  }
}
