@use 'sass:math';
@import '../../../../styles/resources/colors';
@import '../../../../styles/resources/variables';
@import '../../../../styles/resources/mixins';

.errors {
  margin-bottom: 2rem;
  border: 2px solid theme-color('highlight');
  color: theme-color('highlight');
  padding: 1rem;
}

.basket-message {
  display: block;
  text-transform: uppercase;
  color: #ff0000;
  font-weight: bold;
  font-family: "Space Grotesk", "sans-serif";
  border: 2px solid #ff0000;
  padding: 1rem;
  font-size: 1.5rem;
}