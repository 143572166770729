@use 'sass:math';
@import '../../../../styles/resources/colors';
@import '../../../../styles/resources/variables';
@import '../../../../styles/resources/mixins';

.root {
  // the root element is positioned in the correct place,
  // child items have a dynamic top: Xrem to position them underneath each other
  position: fixed;
  top: 10rem;
  left: 100%;
  z-index: 9999;
  // @TODO should these appear over the modal BG?
}

.notice {
  position: absolute;
  background: #ff9e31;
  height: 5rem;
  padding: 1.5rem;
  color: white;
  display: flex;
  align-items: center;
  white-space: nowrap;
  // animation is way easier than transition in this case. No need to attach/remove classes
  animation-duration: 6s;
  animation-name: slidein;
}

@keyframes slidein {
  0% {
    transform: translateX(0%);
  }

  15% {
    transform: translateX(-100%);
  }

  85% {
    transform: translateX(-100%);
  }

  100% {
    transform: translateX(0%);
  }
}
