// Headings
h1,
h2,
h3,
h4,
h5,
.h1,
.h2,
.h3,
.h4,
.h5 {
  font-weight: 700;
  margin-top: 0;
  margin-bottom: 1.5rem;
  line-height: 0.9;
  font-family: $font_heading;
}

h1,
.h1 {
  @include size-h1;
}

h2,
.h2 {
  @include size-h2;
}

h3,
.h3 {
  @include size-h3;
}

h4,
.h4 {
  @include size-h4;
}

h5,
.h5 {
  @include size-h5;
}

// Paragraphs

p,
.p {
  font-weight: normal;
  line-height: 1.4;
  margin: 0 0 1.5rem;
}

.p-xl {
  @include size-p-xl;
}

.p-lg {
  @include size-p-lg;
}

.p-sm {
  @include size-p-sm;
}

.font-primary {
  font-family: $font_primary;
}

.font-heading {
  font-family: $font_heading;
}

.text-medium {
  font-weight: 500;
}
