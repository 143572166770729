@use 'sass:math';
@import '../../../../styles/resources/colors';
@import '../../../../styles/resources/variables';
@import '../../../../styles/resources/mixins';

.root {
  display: block;

  p {
    display: inline-block;
    padding: 0.5rem 1rem 0.5rem 1rem;
    font-size: 1.4rem;
    color: red;
    margin-top: 1rem;
    border-left: 3px solid red;
    background: #f7f7f7;
    font-style: italic;
    font-weight: bold;
  }
}
