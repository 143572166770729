@use 'sass:math';
@import '../../../../styles/resources/colors';
@import '../../../../styles/resources/variables';
@import '../../../../styles/resources/mixins';

.root {
  background-color: theme-color('primary');
  color: white;
  font-weight: bold;
  border: 0;
  display: inline-block;
  text-align: center;
  padding: 1.9rem 2.7rem;
  transition: color 0.3s, background-color 0.3s, opacity 0.3s;
  text-transform: uppercase;
  cursor: pointer;
  white-space: nowrap;
  font-size: 1.4rem;
  font-weight: bold;

  @include page-width(lg-up) {
    min-width: 16rem;
  }

  &:hover {
    color: white;
    background-color: darken(theme-color('primary'), 15%);
  }

  &.expand {
    width: 100%;
  }

  &.disabled {
    opacity: 0.4;
    cursor: not-allowed;

    &:hover {
      background-color: theme-color('primary');
      color: white;
    }
  }

  &.light {
    background-color: white;
    color: black;

    &:hover {
      background-color: theme-color('primary');
      color: white;
    }
  }

  &.light-hover {
    background-color: white;
    color: black;

    &:hover {
      background-color: black;
      color: white;
    }
  }

  &.dark {
    background-color: black;
    color: white;

    &:hover {
      background-color: white;
      color: black;
    }
  }

  &.grey {
    background-color: theme-color('light-grey');
    color: black;

    &:hover {
      background-color: theme-color('mid-grey');
      color: black;
    }
  }

  &.outline {
    background-color: white;
    border: 1px solid black;
    color: black;

    &:hover {
      background-color: theme-color('primary');
      border-color: theme-color('primary');
      color: white;
    }
  }

  &.text {
    background-color: transparent;
    color: inherit;
  }
}

.thin {
  padding: 1.2rem;
}
