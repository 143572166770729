// Font sizes

@mixin size-h1 {
  font-size: 4.5rem;

  @include page-width(md-up) {
    font-size: 5.4vw;
  }

  @include page-width(xl-up) {
    font-size: 7.5rem;
  }
}

@mixin size-h2 {
  font-size: 3.5rem;

  @include page-width(md-up) {
    font-size: 3.5vw;
  }

  @include page-width(xl-up) {
    font-size: 5rem;
  }
}

@mixin size-h3 {
  font-size: 2.8rem;

  @include page-width(md-up) {
    font-size: 2.5vw;
  }

  @include page-width(xl-up) {
    font-size: 3.5rem;
  }
}

@mixin size-h4 {
  font-size: 2.3rem;

  @include page-width(xl-up) {
    font-size: 2.5rem;
  }
}

@mixin size-h5 {
  font-size: 2rem;
}

@mixin size-p {
  font-size: 1.6rem;
}

@mixin size-p-xl {
  font-size: 2rem;
}

@mixin size-p-lg {
  font-size: 1.8rem;
}

@mixin size-p-sm {
  font-size: 1.3rem;
}
