@use 'sass:math';
@import '../../../../styles/resources/colors';
@import '../../../../styles/resources/variables';
@import '../../../../styles/resources/mixins';

.root {
  button {
    width: 100%;
    height: 100%;

    @include page-width(sm-up) {
      width: auto;
    }
  }
}
