.embla-wrap {
  position: relative;

  &--thumb {
    // display: flex;

    // .embla {
    //   flex: 0 0 80%;

    //   &--thumb {
    //     flex: 0 0 20%;
    //   }
    // }
  }
}

.embla {
  $self: &;
  position: relative;
  margin-left: auto;
  margin-right: auto;

  &--thumb {
    margin-top: 1rem;
  }

  &.no-gutter {
    #{$self}__container {
      margin: 0;
    }

    #{$self}__slide {
      padding: 0;
    }
  }

  &__viewport {
    overflow: hidden;
    width: 100%;
    height: 100%;

    &.is-draggable {
      cursor: move;
      cursor: grab;
    }

    &.is-dragging {
      cursor: grabbing;
    }

    &.show-overflow {
      overflow: visible;
    }
  }

  &__container {
    display: flex;
    user-select: none;
    -webkit-touch-callout: none;
    -khtml-user-select: none;
    -webkit-tap-highlight-color: transparent;
    margin: 0 -1rem;

    &--thumb {
      margin: 0 -0.5rem;
    }
  }

  &__slide {
    position: relative;
    min-width: 100%;
    padding: 0 1rem;

    &--thumb {
      cursor: pointer;
      padding: 0 0.5rem;
      opacity: 0.5;
      transition: opacity 0.3s;
      min-width: 33.333%;

      @include page-width(sm-up) {
        min-width: 25%;
      }

      @include page-width(lg-up) {
        min-width: 20%;
      }

      &.is-selected {
        opacity: 1;
      }
    }
  }

  &__button {
    outline: 0;
    cursor: pointer;
    background-color: transparent;
    color: white;
    touch-action: manipulation;
    position: absolute;
    z-index: 1;
    top: 50%;
    transform: translateY(-50%);
    border: 0;
    width: 5rem;
    height: 5rem;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0;
    transition: opacity 0.3s;

    @include page-width(md-up) {
      width: 7rem;
      height: 7rem;
    }

    @include page-width(xl-up) {
      width: 9rem;
      height: 9rem;
    }

    &:disabled {
      cursor: default;
      opacity: 0;
    }

    &:hover {
      @include page-width(sm-up) {
        &::before {
          background-color: theme-color('primary');
        }
      }
    }

    &::before {
      content: '';
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      border-radius: 50%;
      background-color: rgba(0, 0, 0, 0.5);
      transition: background-color 0.3s;
    }

    &--prev {
      left: 0;

      @include page-width(sm-up) {
        right: 2.7rem;
      }
    }

    &--next {
      right: 0;

      @include page-width(sm-up) {
        right: 2.7rem;
      }
    }

    span,
    i,
    img,
    svg {
      position: relative;
      z-index: 1;
    }
  }

  &__dots {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    z-index: 5;
    display: flex;
    list-style: none;
    padding-left: 0;
    justify-content: center;

    @include page-width(sm-up) {
      padding: 2rem;
    }
  }

  &__dot {
    background-color: transparent;
    cursor: pointer;
    position: relative;
    padding: 0;
    width: 3rem;
    height: 3rem;
    margin-right: 0.75rem;
    margin-left: 0.75rem;
    border: 0;
    display: flex;
    align-items: center;

    &::after {
      background-color: #efefef;
      width: 100%;
      height: 0.4rem;
      content: '';
    }

    &.is-selected {
      &::after {
        background-color: $color_primary;
        opacity: 1;
      }
    }
  }
}

// .embla__slide__inner--thumb {
//   touch-action: manipulation;
//   cursor: pointer;
//   border: 0;
//   outline: 0;
//   margin: 0;
//   padding: 0;
//   height: 100px;
//   width: 100%;
//   background-color: transparent;
//   position: relative;
//   display: block;
//   overflow: hidden;
// }

// .embla__slide__thumbnail {
//   position: absolute;
//   opacity: 0.2;
//   top: 0;
//   bottom: 0;
//   left: -10000%;
//   right: -10000%;
//   margin: auto;
//   min-width: 1000%;
//   min-height: 1000%;
//   max-width: none;
//   transform: scale(0.1);
//   transition: opacity 0.2s;
// }

// .embla__slide--thumb.is-selected .embla__slide__thumbnail {
//   opacity: 1;
// }
