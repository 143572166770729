@use 'sass:math';
@import '../../../../styles/resources/colors';
@import '../../../../styles/resources/variables';
@import '../../../../styles/resources/mixins';

.root {
  width: 100%;
  height: 100%;
}

.text {
  fill: black;
}

.icon {
  fill: black;
}
