@use 'sass:math';
@import '../../../../styles/resources/colors';
@import '../../../../styles/resources/variables';
@import '../../../../styles/resources/mixins';

// .wrapper has overflow: hidden, to stop the scrollbar appearing on the body.
.wrapper {
  position: fixed;
  width: 100%;
  bottom: 0;
  left: 0;
  overflow: hidden;
  z-index: 2147483001; // 1 more than Gorgias
}

.root {
  margin-top: 2px;
  border-top: 1px solid black;
  position: relative;
  transition: transform 0.3s;
  transform: translateY(100%);
  background-color: theme-color(primary);
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  padding: 1em;

  h3 {
    align-items: center;
    height: 100%;
  }

  input {
    width: 28px;
    height: 28px;
    cursor: pointer;
  }

  a {
    color: black;
    text-decoration: underline;
  }
}

.animate {
  transform: translateY(0%);
}

.content {
  flex: 1 0 100%;
  font-size: 14px;
  margin-bottom: 1em;
}

.options {
  flex: 1 0 220px;
  display: flex;
  justify-content: flex-start;
}

.option {
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: pointer;
  padding-right: 1em;
}

.title {
  font-weight: 600;
}

@media (min-width: 900px) {
  .root h3 {
    flex: 0 0 130px;
    margin: 0;
    margin-right: 2rem;
  }
  .content {
    flex: 3 0 200px;
    margin-bottom: 0;
  }
  .options {
    justify-content: center;
  }
}
