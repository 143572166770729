@use 'sass:math';
@import '../../../../styles/resources/colors';
@import '../../../../styles/resources/variables';
@import '../../../../styles/resources/mixins';

$size_variation: 4.5rem;
$color_disabled: #e3bfbf;

// variations

.variations {
  ul {
    display: flex;
    flex-wrap: wrap;
    list-style-type: none;
    padding: 0;
    margin: 0 -0.3rem;

    li {
      padding: 0 0.3rem 0.3rem;
    }
  }

  label {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    width: $size_variation - 0.5rem;
    height: $size_variation - 0.5rem;

    @include page-width(sm-up) {
      width: $size_variation;
      height: $size_variation;
    }

    input,
    span {
      width: 100%;
      height: 100%;
    }

    input {
      position: absolute;
      left: 0;
      top: 0;
      z-index: 2;
      opacity: 0;
      visibility: hidden;
    }

    span {
      display: block;
      border-radius: 50%;
      position: relative;
      z-index: 1;
      overflow: hidden;
      text-transform: uppercase;
      font-weight: bold;
      font-size: 1.4rem;
      line-height: $size_variation - 0.5rem;

      @include page-width(sm-up) {
        line-height: $size_variation;
      }
    }
  }

  &.variation-sizes {
    label {
      span {
        box-shadow: inset 0px 0px 0px 1px theme-color('mid-grey');
        background-color: white;
        color: black;
        text-align: center;
      }

      input {
        &:checked ~ span {
          background-color: theme-color('primary');
          border-color: theme-color('primary');
          color: white;
        }
      }

      &.disable-size {
        span {
          position: relative;
          box-shadow: inset 0px 0px 0px 3px $color_disabled;
          color: theme-color('mid-grey');

          &:after {
            content: '';
            display: block;
            width: 3px;
            height: $size_variation;
            position: absolute;
            top: 0;
            left: 50%;
            transform-origin: 100% 50%;
            transform: translateX(-3px) rotate(30deg);
            z-index: 2;
            background-color: $color_disabled;
            pointer-events: none;
          }
        }
      }

      &.not-available {
        cursor: not-allowed;
      }
    }
  }

  &.variation-colors {
    label {
      span {
        text-indent: -9999px;
        opacity: 0.5;
        border: 1px solid transparent;
      }

      input {
        &:checked ~ span {
          opacity: 1;
          border-color: black;
        }
      }
    }
  }

  .variation-label {
    font-weight: bold;
    text-transform: uppercase;
    margin-bottom: 1rem;

    @include page-width(lg-up) {
      margin-bottom: 0;
      margin-right: 1rem;
    }
  }

  .variation-wrap {
    display: flex;
    flex-flow: column;
    margin-bottom: 1rem;

    @include page-width(lg-up) {
      flex-flow: row;
      align-items: center;
    }
  }
}
