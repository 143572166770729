@use 'sass:math';
@import '../../../../styles/resources/colors';
@import '../../../../styles/resources/variables';
@import '../../../../styles/resources/mixins';

.root {
  .item {
    margin-bottom: 3rem;

    .item-image {
      position: relative;
      padding-bottom: 115%;
      background-color: theme-color('light-grey');

      img {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
      }

      .quantity {
        position: absolute;
        z-index: 1;
        bottom: 1rem;
        left: 0;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }

    .price {
      ins {
        text-decoration: none;
        color: theme-color('highlight');
      }

      del {
        font-weight: normal;
        margin-left: 0.5rem;
        font-size: 85%;
      }
    }

    .remove {
      cursor: pointer;
    }
  }
}
