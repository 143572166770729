@use 'sass:math';
@import '../../../../styles/resources/colors';
@import '../../../../styles/resources/variables';
@import '../../../../styles/resources/mixins';

// Wrapper

.root {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 100;
  display: flex;
  align-items: center;
  justify-content: center;

  &.open {
    .modal-background {
      opacity: 1;
    }

    .modal {
      opacity: 1;
      transform: translateY(0);
      transition: all 0.3s ease-in-out;
    }
  }

  &.large {
    .modal {
      max-width: 75rem;
    }
  }

  .close {
    display: flex;
    justify-content: flex-end;

    button {
      border: 0;
      background: transparent;
      padding: 1rem;
      color: black;
    }

    @include page-width(sm-up) {
      display: block;
      position: absolute;
      top: 0;
      right: 0;
    }
  }
}

.modal-background {
  opacity: 0;
  transition: opacity 0.5s;
  will-change: opacity;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
}

// Modal

.modal {
  opacity: 0;
  position: relative;
  z-index: 1;
  will-change: transform, opacity;
  transition: all 0.5s ease-in-out;
  transform: translateY(-3rem);
  overflow-y: auto;
  width: 90vw;
  height: auto;
  max-height: 95vh;
  background-color: white;
  box-shadow: 0px 10px 18px -5px rgba(0, 0, 0, 0.36);

  @include page-width(sm-up) {
    width: 100%;
    max-width: 50rem;
    max-height: 90vh;
  }
}

.inner {
  width: 100%;
  padding: 2rem;

  @include page-width(md-up) {
    padding: 3rem;
  }

  @include page-width(lg-up) {
    padding: 4rem;
  }
}

// Header

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 4rem;

  &.has-border {
    padding-bottom: 2rem;
    border-bottom: 2px solid black;
  }

  svg {
    height: 3rem;
    max-width: 16rem;

    @include page-width(md-up) {
      max-width: 20rem;
    }
  }
}

// Content
