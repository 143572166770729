// Responsive

$sizes: (
  'xxs': 320px,
  'xs': 500px,
  'sm': 768px,
  'md': 1024px,
  'lg': 1216px,
  'xl': 1408px,
  'xxl': 1604px
);

$container-widths: (
  'xxs': auto,
  'xs': auto,
  'sm': 750px,
  'md': 960px,
  'lg': 1152px,
  'xl': 1344px,
  'xxl': 1540px
);

@mixin page-width($range) {
  @each $label, $size in $sizes {
    @if #{$range} == #{$label}-up {
      @media (min-width: #{$size}) {
        @content;
      }
    }

    @if $range == #{$label}-down {
      @media (max-width: #{$size - 1}) {
        @content;
      }
    }
  }
}

// Global

$font_primary: 'noto-sans', 'sans-serif';
$font_heading: 'Space Grotesk', 'sans-serif';
