@use 'sass:math';
@import '../../../../../styles/resources/colors';
@import '../../../../../styles/resources/variables';
@import '../../../../../styles/resources/mixins';

.root {
  display: flex;
  align-items: center;
  position: relative;
  margin-left: 2rem;
  height: 100%;

  @include page-width(lg-up) {
    margin-left: 3rem;
    flex: 0 1 100%;
  }
}

.title {
  position: relative;
  padding: 1rem 4.5rem 1rem 0.5rem;
  width: 100%;
  width: 20rem;
}

.label {
  display: inline-block;
  position: relative;
  padding-right: 1.5rem;
  pointer-events: none;

  span {
    text-transform: uppercase;
    font-weight: bold;
    color: white;
    display: block;
    opacity: 1;
    transition: opacity 0.3s;
  }

  svg {
    display: block;
    position: absolute;
    left: 100%;
    top: 50%;
    transform: translate(0, -50%);
    transition: left 0.3s;
  }
}

.input {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  border: 0;
  background-color: transparent;
  height: 100%;
  color: white;
  opacity: 0;
  transition: opacity 0.3s;
  padding-left: 3rem;
  border-bottom: 1px solid white;
  @include size-p-lg();

  &:focus {
    outline: none;
    opacity: 1;

    & + .label {
      span {
        opacity: 0;
      }

      svg {
        left: 0;
      }
    }
  }
}

.results {
  position: absolute;
  top: calc(100% - 1rem);
  left: 0;
  min-width: 100%;
  width: auto;
  max-height: 25rem;
  overflow-y: auto;
  background-color: white;
  color: black;
  border: 2px solid theme-color('primary');
  white-space: nowrap;
  padding: 2rem;
  box-shadow: 0px 10px 18px -5px rgba(0, 0, 0, 0.36);
  z-index: 100;

  @include page-width(lg-up) {
    min-width: 30rem;
  }
}

.result-section {
  margin-bottom: 2.5rem;

  &:last-of-type {
    margin-bottom: 0;
  }
}

.results-title {
  color: theme-color('mid-grey');
  border-bottom: 2px solid theme-color('mid-grey');
  padding-bottom: 1.5rem;
  font-weight: bold;
}
