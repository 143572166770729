@use 'sass:math';
@import '../../../../styles/resources/colors';
@import '../../../../styles/resources/variables';
@import '../../../../styles/resources/mixins';

$tx_duration: 0.5s;

.offcanvas {
  position: fixed;
  top: 0;
  right: calc(100% + 1px);
  height: 100%;
  transition: transform $tx_duration ease-in-out;
  will-change: transform;
  z-index: 20;
  overflow-x: hidden;
  overflow-y: auto;
  background-color: white;
  width: 85%;
  padding: 1.5rem;

  @include page-width(sm-up) {
    padding: 2rem;
  }

  .close {
    display: flex;
    justify-content: flex-start;
    margin: 0 0 1.5rem 0;

    button {
      color: black;
      background: transparent;
      border: 0;
      padding: 0;
    }
  }

  @include page-width(sm-up) {
    width: 70%;
  }

  @include page-width(md-up) {
    padding: 4rem;
  }

  @include page-width(lg-up) {
    width: 75rem;
  }

  &.open {
    transform: translateX(100%);
  }

  &.right {
    left: 100%;
    right: auto;

    &.open {
      transform: translateX(-100%);
    }
  }
}

.offcanvas-overlay {
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  transition: opacity $tx_duration;
  will-change: opacity;
  z-index: 19;
  opacity: 0;
  pointer-events: none;

  &.open {
    pointer-events: auto;
    opacity: 1;
  }
}
