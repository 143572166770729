@use 'sass:math';
@import '../../../../styles/resources/colors';
@import '../../../../styles/resources/variables';
@import '../../../../styles/resources/mixins';

.root {
  position: absolute;
  top: 0;
  right: 0;
  margin: 1.5rem;
  padding: 0;
  display: flex;
  list-style-type: none;
  z-index: 3;

  &.basket {
    margin: 1rem;

    .label {
      padding: 0.5rem 0.7rem;
    }
  }

  &.stack {
    display: block !important;
  }

  li {
    margin: 0 0.5rem;

    &.stack {
      margin: 0 0 10px 0 !important;
    }

    &:last-of-type {
      margin-right: 0;
    }
  }

  .label {
    background-color: red;
    color: white;
    padding: 0.7rem 1.2rem;
    text-align: center;
    text-transform: uppercase;
    font-weight: bold;
    font-size: 1.2rem;
    white-space: nowrap;

    &.new {
      background-color: blue;
    }

    &.bundle {
      background-color: orange;
    }
  }
}
