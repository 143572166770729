@use 'sass:math';
@import '../../../styles/resources/colors';
@import '../../../styles/resources/variables';
@import '../../../styles/resources/mixins';

// Top level wrapper
.root {
  background-color: transparent;

  // Title
  .title {
    text-align: left;
    color: theme-color('mid-grey');
  }

  // List styling
  .list {
    list-style-type: none;
    padding-left: 0;
    margin-bottom: 0;
    margin-top: 0;
  }

  // List items
  .item {
    text-transform: uppercase;
    font-weight: bold;
    font-family: $font_heading;
    line-height: 1;

    > a,
    > span {
      display: flex;

      .icon {
        transform: rotate(-45deg);
        font-size: 45%;
        margin-left: 1.5rem;
      }
    }
  }

  // Parent list items
  .parent {
    @include size-h1;
    color: black;

    @include page-width(sm-down) {
      font-size: 4rem;
    }

    @include page-width(md-up) {
      &:hover {
        .parent-title {
          color: transparent;
          -webkit-text-stroke: 1px black;
        }
      }
    }

    // &.has-accordion {
    //   > a {
    //     cursor: url('/icon-plus.png'), default;
    //   }
    // }

    > a,
    > span {
      display: flex;
      align-items: center;
      justify-content: space-between;

      .parent-title {
        flex: 1;
      }
    }

    &.active {
      .parent-title {
        color: black;
        -webkit-text-stroke: 0;
        // cursor: url('/icon-minus.png'), default;
      }
    }
  }

  .group {
    @include size-h2;

    @include page-width(sm-down) {
      font-size: 2.5rem;
    }

    > a,
    > span {
      display: flex;
      align-items: center;
      justify-content: space-between;

      .parent-title {
        flex: 1;
      }
    }

    &.active {
      .parent-title {
        color: black;
        -webkit-text-stroke: 0;
      }
    }
  }

  // Child list items
  .child {
    @include size-h2;

    @include page-width(sm-down) {
      font-size: 2.5rem;
    }

    > a,
    > span {
      color: black;

      @include page-width(md-up) {
        &:hover {
          color: transparent;
          -webkit-text-stroke: 1px black;
        }
      }
    }
  }

  // Expanding inner element
  .inner {
    height: 0;
    overflow: hidden;
    transition: height ease 0.2s;
    list-style-type: none;
    padding: 0;
    margin: 0;

    .list {
      padding-left: 1.5rem;
      padding-top: 1.5rem;
      padding-bottom: 1.5rem;

      @include page-width(lg-up) {
        padding-left: 4rem;
        padding-top: 2rem;
        padding-bottom: 2rem;
      }

      &.list-single {
        padding: 0 !important;
      }
    }
  }
}
